'use client';
import { Fragment, useRef, ReactNode } from 'react';
import { Listbox, ListboxButton, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { cva, cx, VariantProps } from 'class-variance-authority';
import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

export type SelectOption<T extends any = any> = {
  id: string;
  label?: string;
  JSXLabel?: JSX.Element;
  value: T;
  selectedDisplay?: ReactNode;
};
export type FormSelectProps<T extends FieldValues = FieldValues> =
  UseControllerProps<T> &
    VariantProps<typeof SELECT_STYLES> & {
      options: SelectOption[];
      multiple?: boolean;
      allowEmpty?: boolean;
      disabled?: boolean;
      placeholder?: string;
      customPlaceholder?: JSX.Element;
      onChange?: (option: SelectOption) => void;
      inModal?: boolean;
      control: Control<T>;
      renderSelected?: (option: SelectOption) => ReactNode;
    };

export const SELECT_STYLES = cva(
  'relative w-full rounded-[1.5rem] leading-input text-[1.5rem] py-[1.3rem] px-[2.4rem] bg-brand-darker-white focus:shadow-none focus:ring-0 text-left font-semibold text-zinc-500',
  {
    variants: {
      error: {
        true: 'focus:ring-0 outline outline-2 outline-brand-red focus:outline-brand-red',
        false:
          'outline-none focus:outline focus:outline-2 focus:outline-brand-primary',
      },
      disabled: {
        true: 'cursor-not-allowed opacity-50',
      },
      defaultVariants: {
        error: 'false',
      },
    },
  }
);

function FormSelect<T extends FieldValues>({
  allowEmpty = true,
  options,
  placeholder = '',
  error,
  disabled,
  inModal = false,
  multiple = false,
  onChange: customOnChange,
  control,
  renderSelected,
  ...controllerProps
}: FormSelectProps<T>) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const {
    field: { value, onChange },
    formState: { errors },
  } = useController({ ...controllerProps, control });

  const handleChange = (newValue: any) => {
    if (customOnChange) {
      const selectedOption = options.find(
        (option) => option.value === newValue
      );
      if (selectedOption) {
        customOnChange(selectedOption);
      }
    }
    onChange(newValue);
  };

  if (allowEmpty && !!value && !options?.some((o) => o.id === 'empty')) {
    options.unshift({ id: 'empty', label: '', value: '' });
  }
  const selectedOption = options.find(
    (o) => JSON.stringify(o.value) === JSON.stringify(value)
  );
  const isError = !!(Boolean(errors[controllerProps.name as string]) || error);
  return (
    <Listbox
      value={value ?? ''}
      onChange={handleChange}
      disabled={disabled}
      multiple={multiple}
    >
      {({ open }) => {
        return (
          <div className="relative w-full">
            <ListboxButton
              className={SELECT_STYLES({
                error: isError,
                disabled,
              })}
              ref={inModal ? buttonRef : null}
            >
              <span className="block min-h-[2.25rem] truncate">
                {multiple
                  ? value
                    ? (value as any[])
                        .filter((v: any) => options.find((o) => o.value === v))
                        .join(', ')
                    : placeholder
                  : selectedOption
                  ? renderSelected
                    ? renderSelected(selectedOption)
                    : selectedOption.selectedDisplay ||
                      selectedOption.label ||
                      placeholder
                  : placeholder}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-[2rem] flex items-center pr-2">
                <ChevronDownIcon
                  className="h-8 w-8 text-brand-gray"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-2 max-h-96 w-full overflow-auto rounded-[.8rem] bg-brand-white py-1 text-[1.4rem] shadow-sm ring-1 ring-brand-black ring-opacity-5 focus:outline-none">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      cx(
                        active ? 'bg-brand-secondary-light' : '',
                        'relative flex h-10 select-none items-center pl-10 pr-9 text-black'
                      )
                    }
                    value={option.value ?? options[0].value}
                    onClick={() => {
                      // q: wtf is this?
                      // a: when in a modal, this listbox options don't close when clicked
                      // so in order to manually close it, we're calling a click event on the button
                      // after a time out -- it needs to be at the end of the event loop to close the listbox
                      if (inModal)
                        setTimeout(() => {
                          buttonRef.current?.click();
                        }, 0);
                    }}
                  >
                    {({ selected }) => (
                      <>
                        <span className={cx('block truncate')}>
                          {option.label}
                        </span>

                        {selected ? (
                          <span
                            className={cx(
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        );
      }}
    </Listbox>
  );
}

export function generateSelectOptions<T>(
  strings: T[],
  labels?: Record<string, string>
): SelectOption<T>[] {
  return !labels
    ? strings.map((str) => ({
        id: String(str),
        label: String(str),
        value: str,
      }))
    : strings.map((str) => ({
        id: String(str),
        label: labels[String(str)],
        value: str,
      }));
}

export default FormSelect;

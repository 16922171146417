import { BrandProp } from 'app/brands/components/Brands';
import { Condition } from 'models/product';
import { Gender, LETTER_SIZES, BOOT_SIZES, PANT_SIZES } from 'models/shared';

export const CONDITIONS = ['New', 'Used'] as readonly Condition[];

// could get in trouble for this :)
export const GENDERS = ['U', 'M', 'W', 'Y'] as readonly Gender[];
export const GENDERS_FULL = ['unisex', 'men', 'women', 'youth'];

export function getGenderLabel(g: Gender) {
  switch (g) {
    case 'M':
      return 'Men';
    case 'W':
      return 'Women';
    case 'Y':
      return 'Youth';
    case 'U':
      return 'Unisex';
    default:
      return '';
  }
}

export function getSizeLabel(s: (typeof LETTER_SIZES)[number]) {
  switch (s) {
    case 'XS':
      return 'Extra Small';
    case 'S':
      return 'Small';
    case 'M':
      return 'Medium';
    case 'L':
      return 'Large';
    case 'XL':
      return 'Extra Large';
    case 'XXL':
      return 'XXL';
    case 'XXXL':
      return 'XXXL';
  }
}
export function getGenderOptions() {
  return GENDERS.map((g) => ({
    label: getGenderLabel(g),
    value: g,
    id: g,
  }));
}
export function getConditionOptions() {
  return CONDITIONS.map((c) => ({
    label: c,
    value: c,
    id: c,
  }));
}

export function getBrandOptions(
  brands: BrandProp[],
  type?: 'gear' | 'parts' | 'cycling'
) {
  if (!brands?.length) return [];
  return brands
    .filter(
      (brand) =>
        !type ||
        (type === 'gear' && brand.riding_gear) ||
        (type === 'parts' && brand.bike_parts) ||
        (type === 'cycling' && brand.mountain_bike)
    )
    .map((brand) => ({
      label: brand.name,
      value: brand.name,
      id: brand.url,
    }));
}

export function getCurrencyOptions() {
  const CURRENCIES = ['USD', 'CAD'];
  return CURRENCIES.map((currency) => ({
    label: currency,
    value: currency,
  }));
}

export function getSizeOptions(
  type: 'letter' | 'boots' | 'pants',
  gender?: Gender
) {
  if (type === 'letter') {
    return LETTER_SIZES.map((size) => ({
      label: size,
      value: size,
      id: size,
    }));
  } else if (type === 'boots') {
    return BOOT_SIZES[gender ?? 'U'].map((size) => ({
      label: size,
      value: +size,
      id: size,
    }));
  } else if (type === 'pants') {
    return PANT_SIZES[gender ?? 'U'].map((size) => ({
      label: size + '',
      value: +size,
      id: size + '',
    }));
  }
  return [];
}

export const getConditionDescription = (condition: string) => {
  switch (condition) {
    case 'New':
      return 'Brand new, never used, with tags';
    case 'Like new':
      return 'New without tags, no signs of wear. Unused.';
    case 'Good':
      return 'Gently used, minor signs of wear.';
    case 'Fair':
      return 'Used, noticeable signs of wear';
    case 'Poor':
      return 'Used many times, may have broken or damaged parts.';
    default:
      return '';
  }
};

export const getNumber = (numStr?: string | number) => {
  if (typeof numStr === 'number') {
    return numStr;
  }
  return numStr ? Number(numStr.replace(/,/g, '')) : 0;
};

export function getShippingRegionOption() {
  return [
    {
      label: 'Continental U.S.',
      value: 'Continental U.S.',
      id: 'US',
    },
    {
      label: 'Alaska',
      value: 'Alaska',
      id: 'AK',
    },
    {
      label: 'Hawaii',
      value: 'Hawaii',
      id: 'HI',
    },
    {
      label: 'Puerto Rico',
      value: 'Puerto Rico',
      id: 'PR',
    },
    {
      label: 'Mexico',
      value: 'Mexico',
      id: 'MX',
    },
    {
      label: 'Canada',
      value: 'Canada',
      id: 'CA',
    },
    {
      label: 'Europe',
      value: 'Europe',
      id: 'EU',
    },
    {
      label: 'Australia',
      value: 'Australia',
      id: 'AU',
    },
    {
      label: 'South America',
      value: 'South America',
      id: 'SAM',
    },
    {
      label: 'Other',
      value: 'Other',
      id: 'OT',
    },
  ];
}

import { useQuery } from '@tanstack/react-query';
import { getBrandOptions } from '@util/createListingHelpers';
import { getBrands } from '@util/firestore/brands/brands.service';
import { useAuth } from 'context/AuthContext';

export default function useBrandOptions(type?: 'gear' | 'parts' | 'cycling') {
  const { userDoc } = useAuth();
  const brandsResponse = useQuery({
    queryKey: ['brands'],
    queryFn: getBrands,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: !!userDoc?.uid,
  });
  const brandOptions = getBrandOptions(brandsResponse.data ?? [], type);
  brandOptions.push({ id: 'na', label: 'N/A', value: 'na' });
  return {
    brandOptions,
  };
}

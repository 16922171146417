import { Switch } from '@headlessui/react';
import { cx } from 'class-variance-authority';

export default function Toggle({
  enabled,
  label,
  onChange,
  disabled,
}: {
  enabled: boolean;
  label?: string;
  onChange: () => void;
  disabled?: boolean;
}) {
  return (
    <div
      className={cx(
        'flex items-center gap-[0.8rem]',
        disabled ? 'pointer-events-none cursor-not-allowed opacity-50' : ''
      )}
    >
      {label && (
        <span
          className={`font-semibold ${
            enabled ? 'text-brand-secondary' : 'text-black '
          }`}
        >
          {label}
        </span>
      )}

      <Switch
        type="button"
        disabled={disabled}
        checked={enabled}
        onChange={onChange}
        className={cx(
          enabled ? 'bg-brand-secondary' : 'bg-gray-400',
          'relative inline-flex h-[2.4rem] w-[4.4rem] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-1 focus:outline-brand-secondary'
        )}
      >
        <span className="sr-only">Use setting</span>

        <span
          aria-hidden="true"
          className={cx(
            enabled ? 'translate-x-[2rem]' : 'translate-x-0',
            'pointer-events-none inline-block h-[2rem] w-[2rem] transform rounded-full bg-brand-white shadow-lg ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </div>
  );
}
